import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeroHeader from "../../heroHeader"
import "./style.module.scss"

import { GatsbyImage } from "gatsby-plugin-image"
import BlocksContent from "../../blocksContent"

const Header = () => {
  const { sanityDemoSuccessPage: data } = useStaticQuery(graphql`
    query sanityDemoSuccessPage {
      sanityDemoSuccessPage {
        heroHeader {
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          subContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          waveColor
        }
        altFeatures {
          sectionLabel
          sectionText
          sectionTitle
          sectionPortableText {
            _rawColumnContent
          }
          illustration {
            _key
            _type
            caption
            alt
            attribution
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <HeroHeader data={data.heroHeader} />
      <div className="wrapper">
        <div className="svgMax">
          <div className="wrapperInner">
            {data.altFeatures.map(feature => {
              return (
                <div className="featureContainer">
                  <div className="featureText">
                    <h2>{feature.sectionTitle}</h2>
                    {feature.sectionPortableText && (
                      <BlocksContent
                        blocks={feature.sectionPortableText._rawColumnContent}
                      />
                    )}
                  </div>
                  {feature.illustration && feature.illustration.asset && (
                    <div className="featureImgContainer">
                      <GatsbyImage
                        image={feature.illustration.asset.gatsbyImageData}
                        alt={feature.illustration.alt}
                        className="featureImg"
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
