import React from "react"
import Layout from "../components/layout"
import SEO from "../components/demo/seo"
import DemoSuccess from "../components/demo/demoSuccess/index"

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <SEO />
        <DemoSuccess />
      </Layout>
    </div>
  )
}

export default ContactPage
